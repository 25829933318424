.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 2021-04-19: Force text alignment to left. */
.ant-form-item-label {
    text-align: left;
}
/* 2021-04-22: Border radius for Input component */
.ant-btn /*.ant-btn-primary*/ {
    border-radius: 6px;
    margin: 6px;
    min-width: 70px; 
}

/* 2022-08-15 Modal border radius */
.ant-modal-content {
    border-radius: 6px;
    /* background: red; */
}

.table-row-highlight {
    background-color:whitesmoke;
}

.infocard-gridstyle-label {
    /* width: 40%; */
    text-align: left;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.infocard-gridstyle-wrapper {
    /* width: 40%; */
    text-align: left;
    padding: 10px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.blink {
    animation: blinker 1s linear infinite;
}
  
@keyframes blinker {
    50% {
      opacity: 0;
    }
}

/*
Ant Design PageHeader component.
Overwrite color to resolve dark theme not visible issue.
*/
.ant-page-header-heading-title {
    color: #777 !important;
}

.ant-page-header-heading-sub-title {
    color: #777 !important;
}

.ant-page-header-back-button {
    color: #777 !important;
}

